/* General Section Styling */
.features-section {
    background-color: #f9f9f9;
  }
  
  .features-title {
    font-size: 3.5rem;
    font-weight: bold;
    color: #333;
  }
  
  /* Feature Card Styling */
  .feature-card {
    background-color: white;
    border-radius: 15px; /* Rounded corners for a soft rectangular/square look */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; /* Tighten spacing between icon and text */
    text-align: center;
    padding: 20px;
    width: 100%;
    max-width: 300px; /* Limit size for consistency */
    min-height: 350px; /* Ensure a consistent height */
    height: auto;
    transition: transform 0.3s ease;
    overflow: hidden; /* Prevent overflow */
    position: relative;
  }
  
  /* Add Decorative Edges */
  .feature-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: linear-gradient(90deg, #FFD700, #FF5733); /* Gradient border effect */
    border-radius: 15px 15px 0 0; /* Match card border radius */
  }
  
  .feature-card:hover {
    transform: scale(1.05);
  }
  
  /* Icon Styling */
  .feature-icon {
    font-size: 4rem; /* Large icon for visibility */
    color: #FFD700;
    margin-bottom: 15px; /* Reduced spacing below icon */
  }
  
  /* Content Styling */
  .feature-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px; /* Ensure text doesn't touch edges */
  }
  
  .feature-title {
    font-size: 2rem; /* Large title for readability */
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    line-height: 1.4;
    word-wrap: break-word; /* Prevent overflow */
    max-width: 90%;
  }
  
  .feature-text {
    font-size: 1.4rem; /* Larger text for readability */
    color: #555;
    line-height: 1.6;
    max-width: 90%; /* Prevent overflow */
    text-align: center;
    word-wrap: break-word;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .features-title {
      font-size: 3rem;
    }
  
    .feature-card {
      max-width: 280px;
      min-height: 330px;
      padding: 15px;
    }
  
    .feature-title {
      font-size: 1.8rem;
    }
  
    .feature-text {
      font-size: 1.6rem; /* Larger text on mobile for elderly viewers */
    }
  
    .feature-icon {
      font-size: 4.2rem;
    }
  }
  
  @media (max-width: 576px) {
    .features-title {
      font-size: 2.5rem;
    }
  
    .feature-card {
      max-width: 260px;
      min-height: 320px;
      padding: 10px;
    }
  
    .feature-title {
      font-size: 1.6rem;
    }
  
    .feature-text {
      font-size: 1.5rem; /* Further increase for small screens */
    }
  
    .feature-icon {
      font-size: 3.8rem;
    }
  }
  